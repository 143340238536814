<template>
    <div>
        <div>
            <div class="d-flex align-items-center " style="gap:10px 20px">
                <div>
                    <img :src="info.user_picture ? info.user_picture : default_img"
                        class="rounded-circle" style="height:60px;width:60px;border:2px solid #FFAD32" />
                </div>
                <div class="flex-fill">
                    <h3 class=" h4 fw-bolder" style="margin-bottom: .25em;">{{info.user_name}}</h3>
                    <div class="d-flex" style="gap:5px;margin-top:.15em">
                        <div class="badge badge-primary" v-if="info.shelter">
                            <img src="@/static/img_assets/shelter.png" class="w-100 m-auto" style="max-width: 10px;" />
                        </div>
                        <div class="badge badge-primary" v-if="info.vet">
                            <img src="@/static/img_assets/vet.png" class="w-100 m-auto" style="max-width: 10px;" />
                        </div>
                        <!-- <div class="badge badge-primary">
                            <img src="@/static/img_assets/pet-shop.png" class="w-100 m-auto" style="max-width: 10px;" />
                        </div> -->
                        <!-- <div class="badge badge-primary">
                            <img src="@/static/img_assets/hotel.png" class="w-100 m-auto" style="max-width: 10px;" />
                        </div> -->
                        <div class="badge badge-primary" v-if="info.trainer">
                            <img src="@/static/img_assets/trainer.png" class="w-100 m-auto" style="max-width: 10px;" />
                        </div>
                        <div class="badge badge-primary" v-if="info.walker">
                            <img src="@/static/img_assets/walker.png" class="w-100 m-auto" style="max-width: 10px;" />
                            <!-- <img src="@/static/img_assets/groomer.png" class="w-100 m-auto" style="max-width: 10px;" /> -->
                        </div> 
                    </div>
                </div>
            </div>
            <div v-if="info.shelter" class="mb-1">
                <h3 class="text-dark fw-bolder mt-2 h4 mb-0">Shelter</h3>
                <div style="background-color: #FFAD32;height: 1px;margin:.5em 0" class="w-100"></div>
                <div class="d-flex flex-column" style="gap:2px">
                    <h5 class="text-primary h4 mb-0">{{ info.shelter.shelter_name }}</h5>
                    <h5 class="h6 mb-0">{{ info.shelter.shelter_location }}</h5>
                    <h5 class="h6 mb-0">{{ info.shelter.shelter_details }}</h5>
                </div>
            </div>
            <div v-if="info.vet" class="mb-1">
                <h3 class="text-dark fw-bolder mt-2 h4 mb-0">Vet</h3>
                <div style="background-color: #FFAD32;height: 1px;margin:.5em 0" class="w-100"></div>
                <div class="d-flex flex-column" style="gap:2px">
                    <h5 class="text-primary h4 mb-0">{{ info.vet.vet_name }}</h5>
                    <h5 class="h6 mb-0">{{ info.vet.vet_location }}</h5>
                    <h5 class="h6 mb-0">{{ info.vet.vet_details }}</h5>
                </div>
            </div>
            <div v-if="info.trainer" class="mb-1">
                <h3 class="text-dark fw-bolder mt-2 h4 mb-0">Trainer</h3>
                <div style="background-color: #FFAD32;height: 1px;margin:.5em 0" class="w-100"></div>
                <div class="d-flex flex-column" style="gap:2px">
                    <h5 class="text-primary h4 mb-0">{{ info.trainer.trainer_title }}</h5>
                    <h5 class="h6 mb-0">{{ info.trainer.trainer_description }}</h5>
                </div>
            </div> 
            <div v-if="info.walker" class="mb-1">
                <h3 class="text-dark fw-bolder mt-2 h4 mb-0">Walker</h3>
                <div style="background-color: #FFAD32;height: 1px;margin:.5em 0" class="w-100"></div>
                <div class="d-flex flex-column" style="gap:2px">
                    <h5 class="h6 mb-0">{{ info.walker.walker_location }}</h5>
                    <h5 class="h6 mb-0">{{ info.walker.walker_note }}</h5>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        info : Object
    },
    methods: {

    },
    data(){
        return {

        }
    }
}
</script>